  .top-nav {
    background-color: #2061b7;
    color: white;
    padding: 10px 20px;
    width: 100%;
  }
  .top-nav p {
    font-family: Arial, sans-serif;
    color: white;
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
  }
  .second-div {
    background: linear-gradient(to bottom, #BCCCE8, #87A5D7);
    color: white;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    
  }
  .logo{
    width: 175px;
    margin-left: -10px;
  }
  .call-now{
    width: 150px;
    /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.6);
    border-radius: 20px; */
  }
  .main-div{
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    padding: 30px 10px;
  }
  .main-div > img{
    width: 69px;
  }
  .main-img{
    margin-top: 20px;
    width: 100% !important;
    /* padding-top: 20px; */
    border-radius: 20px;
  }
  .main-title{
    font-size: 23px;
    padding-top: 20px;
    font-weight: 800;
    text-align: center;
    line-height: 34px;
    width: 100%;
  }
  .main-title > span {
    background-color: #FFDE59;
  }
  .place-main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
  }
  .place{
    height: 300px;
    width: 100%;
    background-color: #2061b7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .place > p {
    font-size: 48px;
    font-weight: 800;
    color: white;
    text-align: center;
  }
  .main-lower{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 20px; 
  }
  .lower-box{
    background-color: #2061b7;
    color: #fff;
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 -5px 0 #FFDE59;  
  }
  .top{
    font-size: 18px;
    margin-bottom: 50px;
  }
  .top > span {
    color: #FFDE59;
    font-style: italic;
    font-weight: 600;
  }
  .mid{
    font-size: 18px;
    margin-bottom: 50px;
    text-align: end;
  }
  .bottom{
    font-size: 18px;
  }
  .bottom > span {
    color: #FFDE59;
    font-style: italic;
    font-weight: 600;
  }
  .btn-main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
   
  }
  .btn-main > p { 
    background-color: rgb(3, 206, 3);
    color: #fff;
    padding: 20px 60px;
    border-radius: 30px;
    font-size: 26px;
    font-weight: 800;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  }
  .footer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 30px;
    gap: 10px;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .gif{
    width: 10px;
  }
  .txt{
    font-weight: 700;
    font-size: 12px;
  }
  .green{
    color: rgb(3, 206, 3);
  }
  .underline{
    text-decoration: underline;
  }
  .quiz{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .quiz-main{
    width: 100%;
    margin: 10px 20px;
    text-align: center;
    background-color: #F2F2F2;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 7px 0px 0px #2061b7;  
  }
  .quiz-top{
    background: #2061b7;
    color: #fff;
    padding: 8px 15px;
  }
  .quiz-top > p {
    font-size: 14px;
    font-weight: 500;
    
  }
  .quiz-top > p > span {
    color: #FFDE59;
  }
  .quiz-ques{
    font-size: 24px;
    font-weight: 800;
    margin-top: 20px;
    padding: 10px;
  }
  .quiz-yes, .quiz-no{
    background-color: rgb(4, 214, 4);
    font-size: 24px;
    color: #fff;
    margin-top: 20px;
    padding: 20px 20px;
    border-radius: 30px;
    margin: 10px;
    font-weight: 600;
    white-space: nowrap;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }
  .hand{
    width: 60px;
    position: absolute;
    right: 60px;
  }
  .hand-congo{
    width: 60px;
    position: absolute;
    right: 65px;
  }
  .quiz-loader {
    position: relative;
    width: 100%;
    height: 10px;
    overflow: hidden;
  }
  
  .inner-loader {
    height: 100%;
    background-color: rgb(4, 214, 4);
    transition: width 0.5s ease-in-out;
  }
  
  .loader-dot {
    position: absolute;
    top: -3px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 16px solid rgb(4, 214, 4);
    transition: left 0.5s ease-in-out;
}

  .bottom-cong{
    background-color: #F2F2F2;
    padding: 30px 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 20px;
  }
  .bottom-cong-txt{
    font-size: 22px;
    font-weight: 800;
  }
  .bottom-cong-txt > span {
    background-color: #FFDE59;
    /* font-style: italic; */
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  .bottom-spot{
    background-color: #FFDE59;
    width: 170px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    padding: 4px;
  }
  .bottom-spot > span{
    color: red;
  }
  .tap-to-call{
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
  }
  .below{
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
  }
  .bottom-below{
    font-size: 14px;
    margin-top: 20px;
  }
  .senior{
    font-size: 16px;
    background-color: #24da2a;
    padding: 2px;
    border-radius: 10px;
    margin: 0px 60px;
    text-align: center;
    color: #fff;
    margin-top: 40px;
  }
  .border{
    text-align: center;
    background-color: #2061b7;
    margin: 0px 23px;
    padding: 10px;
    color: #24da2a;
    font-size: 18px;
    font-weight: 700;
    border-top: 10px solid #24da2a;
  }
  .cong-top{
    margin-top: 50px;
  }

  .quiz {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    transition: transform 0.5s ease-in;
  }
  
  .quiz-main {
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }
  .quiz-main.slide-left {
    opacity: 0;
    transform: translateX(-110%);
  }
  .quiz-main.slide-mid {
    opacity: 0;
    transform: translateX(150%);
  }
  .quiz-main.slide-right{
    opacity: 1;
    transform: translateX(0);
  }
  .baarish{
    position: absolute;
    top: 30px;
    z-index: 10000;
  }
  .baarish > img {
    width: 100%;
  }
  .review{
    width: 100%;
  }
  .first{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 80%;
    background: #2061b7;
    padding: 10px;
    gap: 10px;
    font-size: 15px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    margin-top: 50px;
    border-right: 2px solid #24da2a;
  }
  .first > img {
    width: 10px;
  }
  .second{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 80%;
    background: #2061b7;
    padding: 10px;
    gap: 10px;
    font-size: 14px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff;
    margin-left: 20%;
    margin-top: 50px;
    border-left: 2px solid #24da2a;
  }
  .second > img {
    width: 10px;
  }
  .first > p > span {
    color: #24da2a;
    font-style: italic;
  }
  .second > p > span {
    color: #24da2a;
    font-style: italic;
  }
  .carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0px 25px;
    padding-top: 20px;
  }
  
  .carousel-image {
    width: 100%;
    height: auto;

    border-radius: 20px;
  }
  
  /* Optional: Add styles for arrows or indicators */
  /* You can customize these styles further as needed */
  
  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    color: #333;
    padding: 8px;
    border-radius: 50%;
  }
  
  .carousel-arrow.left {
    left: 10px;
  }
  
  .carousel-arrow.right {
    right: 10px;
  }
  
  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  
  .carousel-indicator {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .carousel-indicator.active {
    background-color: #333;
  }

  .ping-container {
    position: relative;
    width: 50px;
    height: 50px;
   }
   
   .ping {
    width: 10px;
    height: 10px;
    background-color: #22c55e;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   }
   
   .pingball{
    width: 10px;
    height: 10px;
    background-color: #22c55e;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ping 2s infinite;
    opacity: 0.7;
   }
   
   @keyframes ping {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.7;
    }
    80% {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
    }
   }
   .checking{
    text-align: center;
    padding: 1.5rem;
    background-color:#e9f2ff;
    border-top: 2px solid rgb(59 130 246);
    margin: 1rem;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #1f2937;
    margin:1.25rem 1rem;
  }

   
  
  
